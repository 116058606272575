import { render, staticRenderFns } from "./header.vue?vue&type=template&id=7645feda&scoped=true"
var script = {}
import style0 from "./header.vue?vue&type=style&index=0&id=7645feda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7645feda",
  null
  
)

export default component.exports