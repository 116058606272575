<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App'
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
}
.img_btn_box{
	display: flex;
	align-items: center;
	justify-content: center;
}

.item_btn_item{
	min-width: 50px;
	height: 30px;
	border: 1px solid #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	border-radius: 10px;
}

.item_btn_item_h{
	color: #eeb768;
	border: 1px solid #eeb768;
}
</style>
