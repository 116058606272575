import {
	getRequest,
	postRequest,
} from './requset.js'
import {
	jsonRequest
} from './requset.js'
import {
	formRequest
} from './requset.js'
import {
	formHeaderRequest
} from './requset.js'
import * as Url from './Url.js'

/**
 *               @示例
 * 
 * 
export const shareRegisterReuqest = (paramObj,proxy) => {
    return getRequest(Url.REGISTER_URL, paramObj,proxy);
}

export const testReuqest = (paramObj,proxy) => {
    return getRequest(Url.TEST, paramObj,proxy);
}


export const loginReuqest = (paramObj,proxy) => {
    return getRequest(Url.LOGIN_URL, paramObj,proxy);
}
 * 
 * 
 * 
*/

/**
 * @登录
*/
export const login = (paramObj) => {
	return postRequest(Url.LOGIN_URL, paramObj);
}


/**
 * @用户列表
 */
// 用户列表
export const userList = (paramObj) => {
	return postRequest(Url.USERLIST, paramObj);
}
// 添加用户
export const addUser = (paramObj) => {
	return postRequest(Url.ADDUSER, paramObj);
}
// 修改用户信息
export const profile = (paramObj) => {
	return postRequest(Url.PROFILE, paramObj);
}




/**
 * @首页
*/
export const getHome = (paramObj) => {
	return postRequest(Url.GETHOME, paramObj);
}

/**
 * @系统设置
*/
// 获取系统设置
export const systemConfig = (paramObj) => {
	return postRequest(Url.SYSTEMCONFIG, paramObj);
}

// 修改系统设置
export const setSystemConfig = (paramObj) => {
	return postRequest(Url.SETSYSTEMCONFIG, paramObj);
}






/**
 * @业务管理
*/

/**
 * @新增报价
*/
// 上传图片
export const upload = (paramObj) => {
	return postRequest(Url.UPLOAD, paramObj);
}

// 提交报价  编辑报价
export const addOrEidtStore = (paramObj) => {
	return postRequest(Url.ADDOREIDTSTORE, paramObj);
}

// 报价员列表
export const getBaomenber = (paramObj) => {
	return postRequest(Url.BAOMENBER, paramObj);
}


/**
 * @加工参数
*/


//材料管理列表  一级二级材料分类  表面处理分类枚举
export const getCategoryList = (paramObj) => {
	return getRequest(Url.GETCATEGORYLIST, paramObj);
}

//添加  一级二级材料分类  表面处理分类
export const addStore = (paramObj) => {
	return getRequest(Url.ADDSTORE, paramObj);
}

//编辑  一级二级材料分类  表面处理分类
export const editCategory = (paramObj) => {
	return getRequest(Url.EDITCATEGORY, paramObj);
}




/**
 * @报价管理
*/
export const getOfferList = (paramObj) => {
	return postRequest(Url.GETOFFERLIST, paramObj);
}

// 报价详情
export const getOfferInfo = (paramObj) => {
	return postRequest(Url.GETOFFERINFO, paramObj);
}

// 确认提交报价
export const confirmStore = (paramObj) => {
	return postRequest(Url.CONFIRMSTORE, paramObj);
}

// 转交报价员
export const transferStore = (paramObj) => {
	return postRequest(Url.TRANSFERSTORE, paramObj);
}

// 回复客户
export const replyStore = (paramObj) => {
	return postRequest(Url.REPLYSTORE, paramObj);
}

// 撤回修改
export const recallStore = (paramObj) => {
	return postRequest(Url.RECALLSTORE, paramObj);
}

// 重置提示状态
export const readOfferStore = (paramObj) => {
	return postRequest(Url.READOFFERSTORE, paramObj);
}




/**
 * @材料管理
*/
// 获取材料分类列表
export const getMaterialtype = (paramObj) => {
	return getRequest(Url.MATERIALTYPE, paramObj);
}

// 根据分类查询材料列表
export const getMaterial = (paramObj) => {
	return getRequest(Url.MATERIAL, paramObj);
}



/**
 * @订单管理
*/
// 订单列表
export const getOrderList = (paramObj) => {
	return postRequest(Url.GETORDERLIST, paramObj);
}

// 订单详情
export const getOrderInfo = (paramObj) => {
	return postRequest(Url.GETORDERINFO, paramObj);
}

// 订单详情   成交
export const successStore = (paramObj) => {
	return postRequest(Url.SUCCESSSTORE, paramObj);
}


/**
 * @跟单管理
*/

// 跟单列表
export const getGenOrderList = (paramObj) => {
	return postRequest(Url.GETGENORDERLIST, paramObj);
}

// 完工提交
export const completeStore = (paramObj) => {
	return postRequest(Url.COMPLETESTORE, paramObj);
}

// 退单
export const refundStore = (paramObj) => {
	return postRequest(Url.REFUNDSTORE, paramObj);
}




















// 解析传入参数
const analyzePar = (params) => {
	let str = ""
	for (let k in params) {
		// console.log("params",params[k])
		str += '/' + params[k]
	}
	return str
}