// const HOST = "/api";
export const HOST = "https://712web.62.hzgqapp.com";
export const baseUrl = "https://712web.62.hzgqapp.com";
const PATH = "/api";

export const REGISTER_URL = HOST + PATH + '/member/activity/registerUrl';
export const TEST = ""

/**
 * @公共接口
 */
// 上传文件
export const UPLOAD = HOST + PATH + '/common/upload';



/**
 * @登录
 */
export const LOGIN_URL = HOST + PATH + '/user/login';



/**
 * @用户列表
 */
export const USERLIST = HOST + PATH + '/user/userList';

// 添加用户
export const ADDUSER = HOST + PATH + '/user/addUser';
// 修改用户信息
export const PROFILE = HOST + PATH + '/user/profile';




/**
 * @首页
*/
export const GETHOME = HOST + PATH + '/index/index';

/**
 * @系统设置
*/
// 获取系统设置
export const SYSTEMCONFIG = HOST + PATH + '/index/systemConfig';

// 修改系统设置
export const SETSYSTEMCONFIG = HOST + PATH + '/index/setSystemConfig';





/**
 * @业务管理
 */

/**
 * @新增报价
 */

// 添加 编辑 报价  
export const ADDOREIDTSTORE = HOST + PATH + '/offers/addOrEidtStore';


/**
 * @加工参数
*/

//材料管理  材料  分类  表面
export const GETCATEGORYLIST = HOST + PATH + '/category/getCategoryList';

//添加  材料  分类  表面
export const ADDSTORE = HOST + PATH + '/category/addStore';


//编辑  材料  分类  表面
export const EDITCATEGORY = HOST + PATH + '/category/editCategory';



// 报价员列表
export const BAOMENBER = HOST + PATH + '/user/userList';




/**
 * @报价管理
 */

// 报价管理列表
export const GETOFFERLIST = HOST + PATH + '/offers/getOfferList';

// 报价详情
export const GETOFFERINFO = HOST + PATH + '/offers/getOfferInfo';

// 确认提交报价
export const CONFIRMSTORE = HOST + PATH + '/offers/confirmStore';

// 转交报价员
export const TRANSFERSTORE = HOST + PATH + '/offers/transferStore';

// 回复客户
export const REPLYSTORE = HOST + PATH + '/offers/replyStore';

// 撤回修改
export const RECALLSTORE = HOST + PATH + '/offers/recallStore';

// 撤回修改
export const READOFFERSTORE = HOST + PATH + '/offers/readOfferStore';



/**
 * @订单管理
*/
// 订单列表
export const GETORDERLIST = HOST + PATH + '/orders/getOrderList';

// 订单详情
export const GETORDERINFO = HOST + PATH + '/orders/getOrderInfo';

// 提交订单  成交
export const SUCCESSSTORE = HOST + PATH + '/orders/successStore';




/**
 * @跟单管理
*/
// 跟单列表
export const GETGENORDERLIST = HOST + PATH + '/orders/getGenOrderList';

// 完工提交
export const COMPLETESTORE = HOST + PATH + '/orders/completeStore';

// 退单
export const REFUNDSTORE = HOST + PATH + '/orders/refundStore';






















