import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import * as Api from './utils/api.js' // 1、引入app.js
Vue.prototype.$api = Api //  2、挂载全局

import * as Url from './utils/Url.js' // 1、引入请求url
Vue.prototype.$baseUrl = Url.baseUrl;

import use from './utils/use.js'
Vue.prototype.$use = use;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false



// 全局播报
const speechSynthesis = window.speechSynthesis;
const speechText = new SpeechSynthesisUtterance();
import {
	getOfferList,
	readOfferStore,
} from "@/utils/api";

new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		this.timer = setInterval(() => {
			let queryParams = {
				page: 1, //页码
				limit: 1, //每页数量
				status: '99', //状态:0=待报价,1=已报价,2=已取消,99=全部
			}
			if (localStorage.getItem("token")&&localStorage.getItem("userinfo")) {
				let userType = JSON.parse(localStorage.getItem("userinfo")).user_type
				getOfferList(queryParams).then(res => {
					if (res.data.data && res.data.data.length > 0) {
						let data = res.data.data[0]
						if (data.is_notice == 0&&data.is_reply!=1) {
							let title = ''
							if (userType == 2) {
								// 业务员
								if (data.bao_user_id && data.status == 1) {
									title = '有新的报价待回复！'
								} else if(!data.bao_user_id && data.status == 0)  {
									title = '有撤回订单等待处理！'
								}
								if(title==''){
									return
								}
								
							} else if (userType == 3 && data.status == 0 && data.bao_user_id) {
								// 报价员
								title = '有新报价等待处理！'
							} else if (userType == 1) {
								if (data.bao_user_id && data.status == 1) {
									title = '有新的报价待回复！'
								} else if (!data.bao_user_id && data.status == 0) {
									title = '有撤回订单等待处理！'
								} else if (data.status == 0 && data.bao_user_id) {
									// 报价员
									title = '有新报价等待处理！'
								}else{
									return
								}
							}else {
								if(title==''){
									return
								}
							}
							readOfferStore({
								offer_id: data.id
							})
							this.$notify({
								title,
								type: 'warning',
								duration: 0
							});
							speechText.text = title
							// console.log(speechSynthesis.speak(speechText))
							speechSynthesis.speak(speechText);
						}
					} else {
						speechSynthesis.cancel(speechText)
					}
				});
			}
		}, 5000); // 每隔5秒执行一次
	},
	beforeDestroy() {
		clearInterval(this.timer); // 清除定时器
	},
}).$mount('#app')