import {
	Message
} from 'element-ui';

class Use {

	// 提示信息
	msg(message, type = 'info') {
		Message.closeAll()
		if (Boolean(message) === false) return;
		Message({
			message,
			type
		})
	}
	dateTimeInfo(dateTime) {
		let time = new Date(dateTime)
		let year = time.getFullYear()
		let month = time.getMonth() + 1
		let date = time.getDate()
		let hours = time.getHours()
		let minute = time.getMinutes()
		let second = time.getSeconds()

		if (month < 10) {
			month = '0' + month
		}
		if (date < 10) {
			date = '0' + date
		}
		if (hours < 10) {
			hours = '0' + hours
		}
		if (minute < 10) {
			minute = '0' + minute
		}
		if (second < 10) {
			second = '0' + second
		}
		return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
	}
	
	timeInfo(seconds) {
		let hours = Math.floor(seconds / 3600);
		let minutes = Math.floor((seconds % 3600) / 60);
		let remainingSeconds = seconds % 60;

		// 补零操作，确保每个时间单位至少是两位数
		hours = String(hours).padStart(2, '0');
		minutes = String(minutes).padStart(2, '0');
		remainingSeconds = String(remainingSeconds).padStart(2, '0');

		return `${hours}:${minutes}:${remainingSeconds}`;
	}
	
	// 将图片链接最后面得_s.jpg替换为_b.jpg
	replaceImageSuffix(url) {
	  /**
	   * 替换URL中指定的后缀为新的后缀
	   * @param {string} url - 原始图片URL
	   * @return {string} 修改后的新URL
	   */
	  return url.replace(/_s\.jpg$/, '_b.jpg');
	}
}

export default new Use()